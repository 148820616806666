<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName"  class="el-nav">
      <el-tab-pane label="回播管理" name="first">

        <!--        搜索查询-->
        <div class="search space-between" style="margin: 15px 0 30px 0">
          <div class="flex-nowrap top-list">
            <el-select v-model="options" placeholder="请选择" class="search-type" >
              <el-option
                v-for="item in optionsData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="keywords" placeholder="请输入查询条件" class="search-input"></el-input>
<!--            状态
            <el-select v-model="value" placeholder="请选择" class="search-types" >
              <el-option
                v-for="item in optionsType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>-->
            <el-button type="primary" class="search-btn" style="margin-left: 0" @click="screenSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="screenDefault">重置</el-button>
          </div>
        </div>
        <!--        回播-->

        <div v-if="creationData.data && creationData.data.length">
          <div class="list">
            <div class="list-item" v-for="(item,index) in creationData.data" :key="index">
              <div class="list-item-top">
                <img :src="item.last_live && item.last_live.end_live_video && item.last_live.end_live_video.cover_url" alt="" class="bg-img" v-if="item.last_live && item.last_live.end_live_video && item.last_live.end_live_video.cover_url">
                <img src="~assets/images/data/videobg.jpg" alt="" class="bg-img" v-else>
                <div class="mengceng"></div>
                <div class="mengceng-outside"></div>
<!--                <div class="play-video-box"><play-video :file_id="item.content && item.content.MediaUrl" class="play-video"></play-video></div>-->
                <div class="space-between vertical-center list-item-top-t" style="z-index: 9">
                  <div class="dz">
                    <img src="~assets/images/data/dz.png" alt="" style="width: 24px;height: 24px;margin-right: 5px;">
                    {{item.last_live && item.last_live.end_live_video && item.last_live.end_live_video.good_count ? item.last_live.end_live_video.good_count > 9999 ? (item.last_live.end_live_video.good_count / 10000).toFixed(2) + 'W' : item.last_live.end_live_video.good_count  : 0 }}点赞
                  </div>
<!--                  <div class="state">
                    直播中
                  </div>-->
                </div>
                <img src="~assets/images/data/play.png" class="play" v-if="item.last_live != null"  alt="" @click="islbdialogVisible=true,fileid = item.last_live && item.last_live.end_live_video && item.last_live.end_live_video.content && item.last_live.end_live_video.content.MediaUrl">
                <div class="list-item-top-btm">
                  <div class="avatar vertical-center">
                    <img :src="item && item.user && item.user.avatar ? item.user.avatar:''" class=""  alt="">
                    <span class="white-space text-s">{{item && item.user && item.user.nickname ? item.user.nickname :''}}</span>
                  </div>
                  <div class="white-space text-s " style="margin-top: 10px;font-size: 12px">
                    {{ item && item.last_live && item.last_live.end_live_video && item.last_live.end_live_video.title }}
                  </div>
                </div>
              </div>
              <div class="list-item-bottom vertical-center space-between">
                <div class="list-item-bottom-l vertical-center">
                  <img src="~assets/images/index/peo.png" class="peo"  alt="">
                  {{item && item.last_live && item.last_live.end_live_video && item.last_live.end_live_video.looker_count ? item.last_live.end_live_video.looker_count > 9999 ? (item.last_live.end_live_video.looker_count / 10000).toFixed(2) + 'W' : item.last_live.end_live_video.looker_count: 0 }}
                </div>
<!--                <el-dropdown trigger="click" @command="contentStatus($event,item)">-->
<!--                  <span class="el-dropdown-link">-->
<!--                    <img src="~assets/images/index/mores.png" class="moresimg" alt="" style="vertical-align: middle;">-->
<!--                  <img src="~assets/images/index/mores-h.png" class="moresimgh" alt="" style="vertical-align: middle;">-->
<!--                  </span>-->
<!--                  <el-dropdown-menu slot="dropdown">-->
<!--                    <el-dropdown-item command="1">查看</el-dropdown-item>-->
<!--                    <el-dropdown-item command="2">编辑</el-dropdown-item>-->
<!--                  </el-dropdown-menu>-->
<!--                </el-dropdown>-->
                <div class="list-item-bottom-r" @click="view(item.user.uuid)" v-if="item.last_live != null">
                  查看
                </div>
                <div class="list-item-bottom-r" v-else style="cursor: text;color: #93999F;">
                  暂未开播
                </div>
              </div>
            </div>

          </div>
        </div>
        <empty-prompt style="margin-top: 160px" v-else></empty-prompt>

        <!--   分页     -->
        <el-pagination
          v-if="creationData.meta && creationData.meta.last_page > 1"
          class="page"
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          :total="creationData.meta && creationData.meta.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!--    观看人数-->


    <!--    弹框-->

    <el-dialog
      title="录播"
      class="dialog"
      :visible.sync="islbdialogVisible"
      v-if="islbdialogVisible"
      width="400px"
      top="10vh"
    >
      <play-video :file_id="fileid" class="play-video"></play-video>
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button type="primary" @click="islbdialogVisible = false" style="border-radius: 21px;width: 140px;height: 42px;">确 定</el-button>-->
<!--  </span>-->
    </el-dialog>


  </div>
</template>

<script>
import EmptyPrompt from "components/EmptyPrompt";
import PlayVideo from 'components/playVideo'
export default {
  name: "Index",
  data() {
    return {
      isEltab:1,
      isAdd:true,
      name:'',
      value:'',
      //观看
      isDialogVisible:false,
      //弹幕
      isdmDialogVisible:false,
      //录播
      islbdialogVisible:false,
      fileid:'',
      value1: '',
      activeName: 'first',
      input:"",
      creationData: {}, // 接口数据
      // 筛选条件
      page: 1,  // 当前页码
      pageSize: 12, // 当前数据
      options:'',
      optionsType: [
        {
          value: '1',
          label: '直播中'
        },
        {
          value: '2',
          label: '回放'
        },
        {
          value: '3',
          label: '暂停'
        },
        {
          value: '4',
          label: '已结束'
        },
      ],  // 当前选项
      keywords: '', //关键词
      optionsData: [
        {
          value: 'nickname',
          label: '昵称'
        },
        {
          value: 'mobile',
          label: '手机'
        },
        {
          value: 'title',
          label: '标题'
        }
      ],  // 选项数据

    }
  },
  created() {
    // this.queryFun()
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      // console.log(this.$store.state.user.userInfo.uuid)
      let url= this.$api.lives+ `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}`;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.creationData = res.data.data
        }
      })
    },
    // 筛选重置
    screenDefault() {
      this.options= ''
      this.keywords= ''
      this.status= ''
      this.type= ''
      this.screenSubmit()
    },
    // 分页数据
    handleCurrentChange(val) {
      this.page= val
      // this.linkFun()
      this.getList()
    },
    // 搜索查询
    screenSubmit() {
      this.page= 1
      // this.linkFun()
      this.getList()
    },
    //跳转详情
    view(id){
      this.$router.push({path: '/replay/info',query: {
          id:id,
        }})
    },
    // contentStatus(command,val){
    //   if (command === '1'){
    //     this.view(val.user.uuid,val.user.uuid)
    //   }else if(command === '2'){
    //     this.isDialogvideo =true
    //     this.chaptersForm = val
    //   }
    //
    // },

  },
  components: {
    EmptyPrompt,
    PlayVideo,
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/replay/index.less";
.rule{
  background: #FFFFFF;
  border-radius: 10px;
}
</style>
